import React from "react";

const Tag: React.FC<{ id?: string; name: string }> = ({ id, name }) => {
  return (
    <div
      key={`${id}:${name}`}
      className="px-3 py-1.5 text-xs font-semibold tracking-wide border border-bam-orange text-bam-orange"
    >
      {name}
    </div>
  );
};

export default Tag;
