import React, { useEffect, useState } from "react";

import { Link } from "gatsby";
import { IDatasetResponse, ISingleDataset } from "../../../types/datasets";
import useNorthstar from "../../../hooks/northstarHook";
import useURLQueryParameter from "../../../hooks/queryParamHook";
import { useAuthContext } from "../../../contexts/authContext";

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import PageLayout from "../../../components/PageLayout";
import PageContent from "../../../components/PageContent";
import PageBoundary from "../../../components/PageBoundary";
import DatasetDetails from "../../../components/DatasetDetails";
import LoadingStatus from "../../../components/LoadingStatus";

const Dataset: React.FC = () => {
  const [dataset, setDataset] = useState<ISingleDataset>();
  const [translations, setTranslations] = useState<{ [key: string]: string }>(
    {},
  );
  const { accessToken, clearIdentity } = useAuthContext();

  const [docIdQueryParam, _] = useURLQueryParameter("id");
  const { data, isLoading, error } = useNorthstar<IDatasetResponse>(
    `/api/datasets-catalog/dataset`,
    accessToken,
    {
      method: "get",
      params: {
        id: docIdQueryParam,
      },
    },
  );

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  useEffect(() => {
    if (data) {
      setDataset(data.dataset);
      setTranslations(data.translations?.translation);
    }
  }, [data]);

  let tempSEOData = {
    title: "BAM Dataset Catalogue",
    description: "Browse BAM's dataset catalogue to help support your research",
  };

  let datasetDetailsTemplate = (
    <LoadingStatus loadingType="dataset_details_loading" />
  );

  if (error) {
    datasetDetailsTemplate = (
      <LoadingStatus loadingType="dataset_details_error" />
    );
  } else {
    if (isLoading) {
      datasetDetailsTemplate = (
        <LoadingStatus loadingType="dataset_details_loading" />
      );
    } else if (!data || !data.dataset) {
      datasetDetailsTemplate = (
        <LoadingStatus loadingType="dataset_details_error" />
      );
    } else {
      tempSEOData = {
        title: dataset?.name || "",
        description: dataset?.shortServiceDescription || "",
      };
      datasetDetailsTemplate = (
        <DatasetDetails
          dataset={data.dataset}
          translations={data.translations?.translation}
        />
      );
    }
  }

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent>
        <PageBoundary>
          <nav
            className="flex mt-3 font-bebas-neue tracking-wide text-lg"
            aria-label="Breadcrumb"
          >
            <div className="flex item-center space-x-4">
              <Link
                to="/datasets"
                className="text-bam-blue hover:text-bam-bright-blue"
              >
                All Datasets
              </Link>
              <ChevronRightIcon
                className="mt-2 h-3 w-3 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="text-bam-orange">{dataset?.vendor?.name}</div>
            </div>
          </nav>
          {datasetDetailsTemplate}
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default Dataset;
