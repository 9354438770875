import { navigate } from "gatsby";
import { useState, useCallback } from "react";

type UseURLQueryParameterReturnType = [
  string | undefined,
  (newValue: string) => void,
];

export default function useURLQueryParameter(
  name: string,
): UseURLQueryParameterReturnType {
  const [value, setValue] = useState(() => {
    const queryParameters = new URLSearchParams(
      typeof window === "undefined" ? "" : window.location.search,
    );

    const selectedParameter = queryParameters.get(name);

    if (selectedParameter) return decodeURIComponent(selectedParameter);
  });

  const updateQueryParameter = useCallback(
    (newValue: string) => {
      const url = new URL(
        typeof window === "undefined" ? "" : window.location.href,
      );
      if (!newValue) url.searchParams.delete(name);
      else url.searchParams.set(name, newValue);
      setValue(newValue);
      navigate(url.pathname + url.search);
    },
    [name],
  );

  return [value, updateQueryParameter];
}
